<template>
  <div class="container">
    <m-l-g-menu />
    <div class="text-end p-3">
      <c-primary-button
        :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/${marcoLegalEspecificoId}/herramientas/crear`"
      >
        Crear Herramienta
      </c-primary-button>
    </div>
    <c-card title="Herramientas">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Contenido</th>
            <th>Herramienta gratuita</th>
            <th>Categoría</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tool in tools"
            :key="tool.id"
            :class="tool.highlight ? 'c-bg-primary' : ''"
          >
            <td>{{ tool.name }}</td>
            <td>{{ tool.description }}</td>
            <td>{{ tool.content }}</td>
            <td>{{ tool.free ? "Sí" : "No" }}</td>
            <td>{{ tool.category }}</td>
            <td>
              <div class="dropdown">
                <a
                  class="btn btn-sm btn-secondary dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Opciones
                </a>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/${marcoLegalEspecificoId}/herramientas/${tool.id}/editar`"
                      >Editar/Visualizar</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/${marcoLegalEspecificoId}/herramientas/${tool.id}/imagenes`"
                      >Imágenes</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/${marcoLegalEspecificoId}/herramientas/${tool.id}/archivos`"
                      >Archivos</router-link
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="deleteTool(tool.id)"
                      >Eliminar</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import MLGMenu from "@/components/MarcoLegalGeneral/Menu.vue";
import { showNotification } from "@/services/notification";
import { tools } from "@/services/marcos_legales_especificos";
import { remove } from "@/services/tools";

export default {
  components: {
    CCard,
    CPrimaryButton,
    MLGMenu,
  },
  data() {
    return {
      marcoLegalGeneralVersionId: this.$route.params.versionId,
      marcoLegalGeneralId: this.$route.params.id,
      marcoLegalEspecificoId: this.$route.params.mleId,
      tools: [],
      toolParam: this.$route.query.tool_id,
    };
  },
  mounted() {
    this.getTools();
  },
  methods: {
    deleteTool(id) {
      const confirm = window.confirm(
        `¿Estás seguro de eliminar la herramienta con el id ${id}?`
      );
      if (confirm) {
        remove(id).then(() => {
          showNotification(
            204,
            "success",
            "La herramienta ha sido eliminada con éxito."
          );
          this.getTools();
        });
      } else {
        alert("Not removed");
      }
    },
    getTools() {
      tools(this.$route.params.mleId)
        .then((response) => {
          this.tools = response.data;
          this.highlightTool();
        })
        .catch(() => {
          alert("No pudimos cargar las herramientas");
        });
    },
    highlightTool() {
      if (!this.toolParam) {
        return;
      }
      const tool = this.tools.find((tool) => tool.id === this.toolParam);
      tool.highlight = true;
    },
  },
};
</script>
